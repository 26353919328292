const svg = {
  play: '<svg viewBox="0 0 24 24" fill=""><path d="M8 5v14l11-7z"/></svg>',
  pause:
    '<svg viewBox="0 0 24 24" fill=""><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>',
  like:
    '<svg viewBox="0 0 124.065 124.065" fill=""><g><path d="M12.532,77.057c7.7,10.2,17.7,18.4,28.1,25.801c0,0,12.4,8.6,18.1,12.6c2.1,1.4,4.799,1.4,6.799,0c5.7-4,18-12.6,18-12.6c10.4-7.4,20.2-15.5,28-25.801c6.601-8.8,11.5-19.2,12.4-30.2c1.6-20-11.3-39.2-32.7-39.2c-12.5,0-23.5,6.8-29.1,16.9c-5.8-10.2-16.7-17-29.2-17c-21.3,0-34.4,19.2-32.8,39.2C1.032,57.757,5.832,68.257,12.532,77.057z"/></g></svg>',
  dislike:
    '<svg viewBox="0 0 124.26 116.08" fill=""><path d="M.13,46.33c.9,11,5.7,21.5,12.4,30.3,7.7,10.2,17.7,18.4,28.1,25.8,0,0,12.4,8.6,18.1,12.6a6,6,0,0,0,6.8,0l18-12.6c4.24-3,8.36-6.16,12.3-9.5L7,20.56A39.28,39.28,0,0,0,.13,46.33Z"/><path d="M107.88,81.1c1.25-1.45,2.48-2.93,3.65-4.47,6.6-8.8,11.5-19.2,12.4-30.2,1.6-20-11.3-39.2-32.7-39.2a33.22,33.22,0,0,0-29.1,16.9,33.51,33.51,0,0,0-29.2-17A31.86,31.86,0,0,0,20.2,9.7L8.28,0,2.91,6.59l116,94.43,5.37-6.59Z"/></svg>',
  listeners:
    '<svg viewBox="0 0 24 24" fill=""><path d="M0 0h24v24H0z" fill="none"/><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/></svg>',
  volumeOn:
    '<svg viewBox="0 0 24 24" fill=""><path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/></svg>',
  volumeOff:
    '<svg viewBox="0 0 24 24" fill=""><path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/></svg>',
  download:
    '<svg viewBox="0 0 24 24" fill=""><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>',
  share:
    '<svg viewBox="0 0 24 24" fill=""><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/></svg>',
  popup:
    '<svg viewBox="0 0 24 24" fill="" width="20" height="20"><path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"/></svg>',
  facebook:
    '<svg viewBox="0 0 112.196 112.196"><g><circle style="fill:#3B5998;" cx="56.098" cy="56.098" r="56.098"/><path style="fill:#FFFFFF;" d="M70.201,58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34c0-5.964,2.833-15.303,15.301-15.303L71.56,21.81v12.51h-8.151c-1.337,0-3.217,0.668-3.217,3.513v7.585h11.334L70.201,58.294z"/></g></svg>',
  twitter:
    '<svg viewBox="0 0 112.197 112.197"><g><circle style="fill:#55ACEE;" cx="56.099" cy="56.098" r="56.098"/><g><path style="fill:#F1F2F2;" d="M90.461,40.316c-2.404,1.066-4.99,1.787-7.702,2.109c2.769-1.659,4.894-4.284,5.897-7.417c-2.591,1.537-5.462,2.652-8.515,3.253c-2.446-2.605-5.931-4.233-9.79-4.233c-7.404,0-13.409,6.005-13.409,13.409c0,1.051,0.119,2.074,0.349,3.056c-11.144-0.559-21.025-5.897-27.639-14.012c-1.154,1.98-1.816,4.285-1.816,6.742c0,4.651,2.369,8.757,5.965,11.161c-2.197-0.069-4.266-0.672-6.073-1.679c-0.001,0.057-0.001,0.114-0.001,0.17c0,6.497,4.624,11.916,10.757,13.147c-1.124,0.308-2.311,0.471-3.532,0.471c-0.866,0-1.705-0.083-2.523-0.239c1.706,5.326,6.657,9.203,12.526,9.312c-4.59,3.597-10.371,5.74-16.655,5.74c-1.08,0-2.15-0.063-3.197-0.188c5.931,3.806,12.981,6.025,20.553,6.025c24.664,0,38.152-20.432,38.152-38.153c0-0.581-0.013-1.16-0.039-1.734C86.391,45.366,88.664,43.005,90.461,40.316L90.461,40.316z"/></g></svg>',
  close:
    '<svg viewBox="0 0 24 24" fill="" width="16"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>',
  copy:
    '<svg version="1.1" x="0px" y="0px" viewBox="0 0 12 13" style="enable-background:new 0 0 12 13;"><style type="text/css">.st0{fill:#9C9C9C;}</style><g id="Partage"><g id="Partage---Player-Facebook" transform="translate(-1541.000000, -264.000000)"><g id="Group-5" transform="translate(1289.000000, 258.000000)"><path id="Shape" class="st0" d="M260.6,17.4v1c0,0.3-0.3,0.6-0.6,0.6h-7.3c-0.4,0-0.6-0.3-0.6-0.6V9c0-0.3,0.3-0.6,0.6-0.6h1.9V16c0,0.8,0.7,1.4,1.5,1.4H260.6z M260.6,8.6V6h-4.5c-0.4,0-0.6,0.3-0.6,0.6V16c0,0.3,0.3,0.6,0.6,0.6h7.3c0.4,0,0.6-0.3,0.6-0.6V9.3h-2.8C260.9,9.3,260.6,9,260.6,8.6z M263.8,7.9L262,6.2c-0.1-0.1-0.3-0.2-0.5-0.2h-0.2v2.4h2.6V8.3C264,8.1,263.9,8,263.8,7.9L263.8,7.9z"/></g></g></g></svg>',
};

export default function colorSvg(s: string, mainColor: string): string {
  return (svg[s] as string).replace('fill=""', `fill="${mainColor}"`);
}
