exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-error-alert__errorAlert--2zVaV {\n  border: 1px solid #ce0018;\n  background-color: #f97a7a;\n  color: #333;\n  padding: 10px 15px;\n  margin: 15px 0;\n  width: 100%;\n  box-sizing: border-box;\n}\n", ""]);

// exports
exports.locals = {
	"errorAlert": "src-components-error-alert__errorAlert--2zVaV"
};