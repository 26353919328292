import { fromFetch } from 'rxjs/fetch';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import config from '../config';
import { getJson } from '../lib/operators';

interface CurrentTrackApi {
  id: number;
  title: string;
  artist: string;
  cover: string;
  buy_link: string;
  is_live: boolean;
  forced_title: boolean;
}

interface RadioApi {
  idradio: number;
  slug: string;
  name: string;
  slogan: string;
  stream_status: 'started' | 'stopped';
  logo: string;
  website: string;
  lang: string;
  public: boolean;
}

interface ListenerApi {
  listener_count: number;
}

export interface VoteStatus {
  status: 'success' | 'error';
  message?: string;
}
export type StreamType = 'standard' | 'mobile' | 'hd';

export interface Stream {
  format: string;
  bitrate: number;
  url: string;
  type: StreamType;
}

export interface CurrentTrack {
  id: number;
  title: string;
  artist: string;
  cover: string;
  buyLink: string;
  isLive: boolean;
  forcedTitle: boolean;
}

export interface Radio {
  id: number;
  name: string;
  slogan: string;
  streamStatus: 'started' | 'stopped';
  logo: string;
  website: string;
  lang: string;
  public: boolean;
}

function convertToCurrentTrack(track: CurrentTrackApi): CurrentTrack {
  return {
    id: track.id,
    title: track.title,
    artist: track.artist,
    cover: track.cover,
    buyLink: track.buy_link,
    isLive: track.is_live,
    forcedTitle: track.forced_title,
  };
}

function convertToRadio(radio: RadioApi): Radio {
  let { website } = radio;
  if (!website && radio.public) {
    website = `https://${
      radio.lang.indexOf('en') > -1 ? 'www' : radio.lang
    }.radioking.com/radio/${radio.slug}`;
  } else if (!website) {
    website = document.location.href;
  }
  return {
    id: radio.idradio,
    name: radio.name,
    slogan: radio.slogan,
    streamStatus: radio.stream_status,
    logo: radio.logo,
    website,
    lang: radio.lang,
    public: radio.public,
  };
}

export default class PlayerService {
  public url: string;
  public oldApiUrl: string;
  public constructor() {
    const { oldApi, widget } = config.rkApi;
    this.url = `${widget}/radio/`;
    this.oldApiUrl = `${oldApi}/radio/`;
  }

  public getRadioConfig = (slug: string): Observable<Radio> => {
    const url = `${this.url}${slug}`;
    return fromFetch(url).pipe(
      getJson<RadioApi>(),
      map(data => {
        return convertToRadio(data);
      }),
    );
  };

  public getStreamConfig = (slug: string): Observable<Stream[]> => {
    const url = `${this.url}${slug}/stream`;
    return fromFetch(url).pipe(getJson<Stream[]>());
  };

  public getCurrentTrack(slug: string): Observable<CurrentTrack> {
    const url = `${this.url}${slug}/track/current`;
    return fromFetch(url).pipe(
      getJson<CurrentTrackApi>(),
      map(convertToCurrentTrack),
    );
  }

  public getCurrentListeners(slug: string): Observable<number> {
    const url = `${this.url}${slug}/listener`;
    return fromFetch(url).pipe(
      getJson<ListenerApi>(),
      map(data => data.listener_count),
    );
  }

  public voteTrack(id: number, score: number): Observable<VoteStatus> {
    const vote = { vote: score };
    const url = `${this.oldApiUrl}${id}/track/vote`;
    return fromFetch(url, { method: 'POST', body: JSON.stringify(vote) }).pipe(
      map(resp => {
        if (resp.status === 429) {
          throw new Error();
        }
        return resp;
      }),
      getJson<VoteStatus>(),
    );
  }
}
