import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import style from '../components/widget.css';

export const getJson = <T>() => (obs: Observable<Response>): Observable<T> =>
  obs.pipe(switchMap(response => from(response.json())));

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export function handleFormat(player: HTMLElement, forceVertical = false): void {
  if (window.innerWidth < 430 || forceVertical) {
    player.classList.add(style.vertical);
  } else {
    player.classList.remove(style.vertical);
  }
}
