import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextXHRBackend from 'i18next-xhr-backend';
import Component from '../lib/component';
import translations from '../../i18n/translations';
import style from './radio-not-found.css';

export default class RadioNotFound extends Component {
  public constructor() {
    super();
    i18next
      .use(LanguageDetector)
      .use(i18nextXHRBackend)
      .init(
        {
          fallbackLng: 'en',
          debug: false,
          resources: translations,
        },
        () => {
          this.init();
        },
      );
  }

  init(): void {
    let html = `<div class='${style.container}'>`;
    html += `<div class='${style.content}'>`;
    html += `<h1 class='${style.oups}'>${i18next.t('oops')}</h1>`;
    html += `<div class='${style.error}'>${i18next.t('radio_not_found')}</div>`;
    html += `</div></div>`;
    this.updateHtml(html);
  }
}
