const translations = {
  en: {
    translation: {
      share_track: 'Share this track',
      download_track: 'Download this track',
      code_to_integrate: 'Code to embed',
      listening: 'I\'m listening "{{track}}" on {{radio}}"',
      listeners: 'listener',
      listeners_plural: 'listeners',
      do_you_like: 'Do you like this track?',
      text_copied: "It's copied!",
      oops: 'Oops...',
      radio_not_found: 'An error occurred while fetching data.',
    },
  },
  fr: {
    translation: {
      share_track: 'Partager ce titre',
      download_track: 'Télécharger ce titre',
      code_to_integrate: 'Code à intégrer',
      listening: 'J\'écoute "{{track}}" en ce moment sur {{radio}}',
      listeners: 'auditeur',
      listeners_plural: 'auditeurs',
      do_you_like: 'Aimez-vous ce titre ?',
      text_copied: "C'est copié !",
      oops: 'Oups...',
      radio_not_found:
        'Une erreur s’est produite lors de la récupération des données.',
    },
  },
  de: {
    translation: {
      share_track: 'Dieses Titel teilen',
      download_track: 'Diesen Titel downloaden',
      code_to_integrate: 'Einbettungscode',
      listening: 'Ich höre "{{track}}" auf {{radio}}',
      listeners: 'Hörer',
      listeners_plural: 'Zuhörer',
      do_you_like: 'Gefällt Ihnen dieser Titel?',
      text_copied: 'Es ist kopiert!',
    },
  },
  es: {
    translation: {
      share_track: 'Compartir esta pista',
      download_track: 'Descargar esta pista',
      code_to_integrate: 'Código para incrustar',
      listening: 'Estoy escuchando a "{{track}}" en {{radio}}',
      listeners: 'oyente',
      listeners_plural: 'oyentes',
      do_you_like: '¿Te gusta esta pista?',
      text_copied: '¡Está copiado!',
    },
  },
  it: {
    translation: {
      share_track: 'Condividi questo brano',
      download_track: 'Scarica questo brano',
      code_to_integrate: 'Codice da incorporare',
      listening: 'Sto  ascoltando "{{track}}" su {{radio}}',
      listeners: 'ascoltatore',
      listeners_plural: 'ascoltatori',
      do_you_like: 'Ti piace questo brano?',
      text_copied: 'È copiato!',
    },
  },
  pt: {
    translation: {
      share_track: 'Partilhar esta faixa',
      download_track: 'Transferir esta faixa',
      code_to_integrate: 'Código para incorporar',
      listening: 'Estou a ouvir "{{track}}" em {{radio}}',
      listeners: 'ouvinte',
      listeners_plural: 'ouvintes',
      do_you_like: 'Gosta desta faixa?',
      text_copied: 'É copiado!',
    },
  },
};

export { translations as default };
