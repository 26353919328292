exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-view-controllers-main__mainView--2SH1r {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n", ""]);

// exports
exports.locals = {
	"mainView": "src-view-controllers-main__mainView--2SH1r"
};