exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-radio-not-found__container--1v2ql {\n  height: 100%;\n  padding: 10px;\n  background: white;\n  overflow: hidden;\n}\n\n.src-components-radio-not-found__content--9xkuZ {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  max-height: calc(100% - 30px);\n  color: #474747;\n  border-radius: 4px;\n  box-shadow: 0 0 5px 0 rgba(177, 177, 177, 0.5);\n}\n\n.src-components-radio-not-found__oups--_vnfD {\n  font-family: 'Gotham-Bold', sans-serif;\n  font-size: 16px;\n  font-weight: 600;\n  margin-bottom: 20px;\n}\n\n.src-components-radio-not-found__error--lxxBf {\n  font-family: 'Gotham-Book', sans-serif;\n  font-size: 14px;\n  text-align: center;\n  padding: 0 10px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "src-components-radio-not-found__container--1v2ql",
	"content": "src-components-radio-not-found__content--9xkuZ",
	"oups": "src-components-radio-not-found__oups--_vnfD",
	"error": "src-components-radio-not-found__error--lxxBf"
};