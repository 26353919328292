import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextXHRBackend from 'i18next-xhr-backend';
import Cookies from 'js-cookie';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import Component from '../lib/component';
import style from './widget.css';
import translations from '../../i18n/translations';
import PlayerService, { CurrentTrack, Radio, Stream } from '../services/player';
import { Player } from '../view-controllers/main';
import { getCookie, handleFormat } from '../lib/operators';
import PlayerUi from './player-ui';

function isMobile(): boolean {
  let check = false;
  ((a): void => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
    // eslint-disable-next-line dot-notation
  })(navigator.userAgent || navigator.vendor || window['opera']);
  return check;
}

function generateTrackId(track: CurrentTrack): string {
  return track.id + (track.isLive ? '_l' : '');
}

export default class Widget extends Component {
  private readonly player: Player;
  private stream: Stream[];
  private radio: Radio;
  private isPlaying = false;
  private playerService = new PlayerService();
  private volumeBeforeMute = 0.75;
  private currentTrackInterval = 0;
  private isHD = true;
  private currentTrack: CurrentTrack | null = null;

  private audio: HTMLAudioElement = new Audio();
  private playIcon: HTMLElement = document.createElement('svg');
  private pauseIcon: HTMLElement = document.createElement('svg');
  private loader: HTMLElement = document.createElement('svg');
  private likes: HTMLElement[] = [];
  private dislikes: HTMLElement[] = [];
  private artist: HTMLElement = document.createElement('div');
  private title: HTMLElement = document.createElement('div');
  private listeners: HTMLElement = document.createElement('div');
  private cover: HTMLElement = document.createElement('div');
  private download: HTMLElement = document.createElement('svg');
  private slider: HTMLInputElement = document.createElement('input');

  public constructor(player: Player, stream: Stream[], radio: Radio) {
    super({ className: style.container });
    this.player = player;
    this.stream = stream;
    this.radio = radio;
    this.audio.preload = 'metadata';
    this.audio.volume = 0.75;
    document.documentElement.style.setProperty(
      '--secondary-color',
      this.player.secondaryColor,
    );
    document.documentElement.style.setProperty(
      '--main-color',
      this.player.mainColor,
    );
    document.documentElement.style.setProperty(
      '--main-color-opacity',
      `${this.player.mainColor}E5`,
    );

    i18next
      .use(LanguageDetector)
      .use(i18nextXHRBackend)
      .init(
        {
          fallbackLng: 'en',
          debug: false,
          resources: translations,
        },
        () => {
          this.init();
        },
      );
  }

  private init(): void {
    const playerUi = new PlayerUi(
      this.player,
      this.radio,
      this.stream.length > 1,
    );
    const html = playerUi.createPlayer();
    this.updateHtml(html);

    const htmlReady = setInterval(() => {
      const playPause = document.getElementById('play-pause');
      if (playPause) {
        clearInterval(htmlReady);
        this.setupButtonListeners();
        this.setupShareListeners();
        this.setupAudioListeners();
        this.setupHTMLElements();
        this.getCurrentTrack();
        this.currentTrackInterval = window.setInterval(() => {
          this.getCurrentTrack();
        }, 20000);
        if (this.player.listeners) {
          this.getCurrentListeners();
          window.setInterval(() => {
            this.getCurrentListeners();
          }, 20000);
        }
        if (this.player.autoplay) {
          this.play();
        }
        fromEvent(window, 'resize')
          .pipe(throttleTime(250))
          .subscribe(() => {
            this.checkScrollText();
          });
      }
    }, 100);
  }

  setupButtonListeners(): void {
    const playPauseButton = document.getElementById('play-pause');
    const playIcon = document.getElementById('play');
    const pauseIcon = document.getElementById('pause');
    const loader = document.getElementById('loader');
    const volumeOn = document.getElementById('volume-on');
    const volumeOff = document.getElementById('volume-off');
    const slider = document.getElementById('slider') as HTMLInputElement;
    if (
      playPauseButton &&
      playIcon &&
      pauseIcon &&
      loader &&
      volumeOn &&
      volumeOff &&
      slider
    ) {
      this.playIcon = playIcon;
      this.pauseIcon = pauseIcon;
      this.loader = loader;
      playPauseButton.onclick = (): void => {
        if (!this.isPlaying) {
          this.play();
        } else {
          this.pause();
        }
      };
      this.slider = slider;
      this.slider.oninput = (): void => {
        this.slider.style.background = `linear-gradient(to right, ${
          this.player.secondaryColor
        } 0%, ${this.player.secondaryColor} ${+this.slider
          .value}%, transparent ${+this.slider.value}%, transparent 100%)`;
        if (volumeOn.classList.contains(style.hidden)) {
          this.volumeBeforeMute = +this.slider.value / 100;
        } else {
          this.audio.volume = +this.slider.value / 100;
        }
      };
      volumeOn.onclick = (): void => {
        this.volumeBeforeMute = this.audio.volume;
        this.audio.volume = 0;
        volumeOn.classList.add(style.hidden);
        volumeOff.classList.remove(style.hidden);
      };
      volumeOff.onclick = (): void => {
        this.audio.volume = this.volumeBeforeMute;
        volumeOff.classList.add(style.hidden);
        volumeOn.classList.remove(style.hidden);
      };
    }
    const listeners = document.getElementById('listeners');
    if (this.player.listeners && listeners) {
      this.listeners = listeners;
    }
    const download = document.getElementById('download');
    if (this.player.itunes && download) {
      this.download = download;
    }
    const popup = document.getElementById('popup') as HTMLElement;
    if (this.player.popup && popup) {
      popup.onclick = (): void => {
        const popupUrl = `${window.location.href}&a=${
          this.isPlaying ? '1' : '0'
        }`;
        const popupWidth = this.player.format === 'h' ? 470 : 275;
        const popupHeight = this.player.format === 'h' ? 145 : 365;
        const popupWindows = window.open(
          popupUrl,
          'popupplayer',
          `width=${popupWidth},height=${popupHeight},left=${Math.round(
            (screen.width - popupWidth) / 2,
          )},top=${Math.round(
            (screen.height - popupHeight) / 2,
          )},scrollbars=no,location=no,menubar=no, resizable=no, toolbar=no`,
        );
        this.pause();
        if (popupWindows) {
          popupWindows.focus();
        }
      };
    }
    const hd = document.getElementById('HD') as HTMLElement;
    if (this.stream.length > 1 && hd) {
      hd.onclick = (): void => {
        if (this.isHD) {
          hd.classList.add(style.inactive);
        } else {
          hd.classList.remove(style.inactive);
        }
        this.isHD = !this.isHD;
        this.play();
      };
    }
    const likes = Object.values(document.getElementsByClassName(
      'like',
    ) as HTMLCollectionOf<HTMLElement>);
    const dislikes = Object.values(document.getElementsByClassName(
      'dislike',
    ) as HTMLCollectionOf<HTMLElement>);
    this.likes = likes;
    this.dislikes = dislikes;
    for (let i = 0; i < likes.length; i += 1) {
      this.likes[i].onclick = (): void => {
        this.tryVote(1);
      };
      this.dislikes[i].onclick = (): void => {
        this.tryVote(-1);
      };
    }
  }

  setupShareListeners(): void {
    const share = document.getElementById('share');
    const shareZone = document.getElementById('share-zone') as HTMLElement;
    const closeShare = document.getElementById('close-share') as HTMLElement;
    const integrationCode = document.getElementById(
      'integration-code',
    ) as HTMLInputElement;
    const copyCode = document.getElementById('integration-copy') as HTMLElement;
    const copied = document.getElementById('copied') as HTMLElement;
    const shareFacebook = document.getElementById(
      'share-facebook',
    ) as HTMLElement;
    const shareTwitter = document.getElementById(
      'share-twitter',
    ) as HTMLElement;
    if (
      this.player.share &&
      share &&
      shareZone &&
      closeShare &&
      shareFacebook &&
      shareTwitter &&
      integrationCode &&
      copyCode &&
      copied
    ) {
      share.onclick = (): void => {
        if (shareZone.classList.contains(style.hidden)) {
          shareZone.style.backgroundColor = document.documentElement.style.getPropertyValue(
            this.player.format === 'v' || window.innerWidth < 470
              ? '--main-color-opacity'
              : '--main-color',
          );
          shareZone.classList.remove(style.hidden);
          setTimeout(() => {
            shareZone.style.opacity = '1';
          }, 1);
        } else {
          closeShare.click();
        }
      };
      closeShare.onclick = (): void => {
        shareZone.style.opacity = '0';
        setTimeout(() => {
          shareZone.classList.add(style.hidden);
        }, 300);
      };
      shareFacebook.onclick = (): void => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${this.radio.website}`,
          'playerRadioPartage',
          'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=640,height=310',
        );
      };
      shareTwitter.onclick = (): void => {
        if (this.currentTrack) {
          const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            i18next
              .t('listening', {
                track: `${this.currentTrack.title} - ${this.currentTrack.artist}`,
                radio: this.radio.name,
              })
              .toString(),
          )}&url=${this.radio.website}`;
          window.open(shareUrl, 'playerRadioPartage');
        }
      };
      copyCode.onclick = (): void => {
        integrationCode.select();
        document.execCommand('copy');
        integrationCode.setSelectionRange(0, 0);
        copied.style.display = 'block';
        setTimeout(() => {
          copied.style.display = 'none';
        }, 2000);
      };
    }
  }

  setupAudioListeners(): void {
    this.audio.onloadeddata = (): void => {
      this.displayPlayState();
    };
    this.audio.onplay = (): void => {
      this.isPlaying = true;
      this.displayLoadingState();
    };
    this.audio.onpause = (): void => {
      this.displayPauseState();
    };
    this.audio.onerror = (): void => {
      this.currentTrack = null;
      this.displayPauseState();
    };
  }

  setupHTMLElements(): void {
    const player = document.getElementById('player');
    const cover = document.getElementById('cover');
    const title = document.getElementById('current-title');
    const artist = document.getElementById('current-artist');
    if (player && title && artist && cover) {
      this.cover = cover;
      this.title = title;
      this.artist = artist;
      window.onresize = (): void => {
        handleFormat(player, this.player.format === 'v');
        (this.slider
          .previousSibling as HTMLElement).style.width = `${this.slider.offsetWidth}px`;
      };

      handleFormat(player, this.player.format === 'v');
    }
  }

  getStreamLink(): string {
    const hdStream = this.stream.find(s => s.bitrate > 128);
    const mobileStream = this.stream.find(s => s.bitrate < 128);
    const defaultStream = this.stream.find(s => s.bitrate === 128);
    if (defaultStream) {
      if (this.isHD) {
        return hdStream ? hdStream.url : defaultStream.url;
      }
      if (isMobile() && mobileStream) {
        return mobileStream.url;
      }
      return defaultStream.url;
    }
    return this.stream[0].url;
  }

  play(): void {
    if (this.player.cover && this.currentTrack && this.currentTrack.cover) {
      this.cover.setAttribute('src', this.currentTrack.cover);
    } else {
      this.cover.setAttribute('src', this.radio.logo);
    }
    this.audio.src = this.getStreamLink();
    this.audio.play();
  }

  pause(): void {
    this.audio.pause();
    this.audio.src = '';
  }

  displayPauseState(): void {
    this.isPlaying = false;
    this.pauseIcon.classList.add(style.hidden);
    this.playIcon.classList.remove(style.hidden);
    this.loader.classList.add(style.hidden);
    this.cover.setAttribute('src', this.radio.logo);
  }

  displayLoadingState(): void {
    this.pauseIcon.classList.add(style.hidden);
    this.playIcon.classList.add(style.hidden);
    this.loader.classList.remove(style.hidden);
  }

  displayPlayState(): void {
    this.pauseIcon.classList.remove(style.hidden);
    this.playIcon.classList.add(style.hidden);
    this.loader.classList.add(style.hidden);
    clearInterval(this.currentTrackInterval);
    this.currentTrackInterval = window.setInterval(() => {
      this.getCurrentTrack();
    }, 20000);
    this.getCurrentTrack();
  }

  getCurrentTrack(): void {
    this.playerService
      .getCurrentTrack(this.player.slug)
      .subscribe((currentTrack: CurrentTrack) => {
        this.currentTrack = currentTrack;

        (this.title.firstElementChild as HTMLElement).innerText =
          currentTrack.title;
        (this.artist.firstElementChild as HTMLElement).innerText =
          currentTrack.artist;
        if (this.player.cover && currentTrack.cover && this.isPlaying) {
          this.cover.setAttribute('src', currentTrack.cover);
        } else {
          this.cover.setAttribute('src', this.radio.logo);
        }
        this.checkScrollText();

        if (this.player.itunes && currentTrack.buyLink) {
          this.download.classList.remove(style.disabled);
          this.download
            .getElementsByTagName('a')[0]
            .setAttribute('href', currentTrack.buyLink);
        } else {
          this.download.classList.add(style.disabled);
        }
        this.tryVote(0, currentTrack);
      });
  }

  checkScrollText(): void {
    const maxWidth = this.artist.clientWidth;
    const artistSpan: HTMLElement = this.artist
      .firstElementChild as HTMLElement;
    const titleSpan: HTMLElement = this.title.firstElementChild as HTMLElement;
    if (titleSpan && artistSpan) {
      titleSpan.removeAttribute('style');
      artistSpan.removeAttribute('style');
      const artistWidth =
        artistSpan.clientWidth -
        parseFloat(getComputedStyle(artistSpan).paddingLeft || '0');
      const titleWidth =
        titleSpan.clientWidth -
        parseFloat(getComputedStyle(titleSpan).paddingLeft || '0');
      if (artistWidth > maxWidth && titleWidth > maxWidth) {
        artistSpan.style.width = `${Math.max(artistWidth, titleWidth)}px`;
        titleSpan.style.width = `${Math.max(artistWidth, titleWidth)}px`;
      }
      if (maxWidth >= artistWidth) {
        this.artist.classList.add(style.noScroll);
      } else {
        this.artist.classList.remove(style.noScroll);
      }
      if (maxWidth >= titleWidth) {
        this.title.classList.add(style.noScroll);
      } else {
        this.title.classList.remove(style.noScroll);
      }
    }
  }

  getCurrentListeners(): void {
    this.playerService
      .getCurrentListeners(this.player.slug)
      .subscribe(count => {
        if (isNaN(count)) {
          this.player.listeners = false;
          if (this.listeners.parentElement) {
            this.listeners.parentElement.classList.add(style.hidden);
          }
        } else {
          this.listeners.innerText = `${count}`;
          (this.listeners.nextSibling as HTMLElement).innerText = i18next.t(
            'listeners',
            { count },
          );
        }
      });
  }

  tryVote(score: number, track?: CurrentTrack): void {
    if (track) {
      this.canVote(track);
    } else {
      this.playerService.getCurrentTrack(this.player.slug).subscribe(cTrack => {
        if (this.canVote(cTrack)) {
          this.enableVote();
          if (score) {
            this.voteTrack(score, cTrack);
          }
        }
      });
    }
  }

  canVote(track: CurrentTrack): boolean {
    const d = new Date();
    const cookieExp = Number.parseInt(
      getCookie(`${this.radio.id}trackvotets`),
      10,
    );
    const expired = d.getTime() - cookieExp > 7200000;
    if (
      (!expired && (track.isLive && track.forcedTitle)) ||
      getCookie(`${this.radio.id}trackvoteid`) === generateTrackId(track)
    ) {
      this.disableVote();
      return false;
    }
    this.enableVote();
    return true;
  }

  disableVote(): void {
    for (let i = 0; i < this.likes.length; i += 1) {
      this.likes[i].classList.add(style.disabled);
      this.dislikes[i].classList.add(style.disabled);
    }
  }

  enableVote(): void {
    for (let i = 0; i < this.likes.length; i += 1) {
      this.likes[i].classList.remove(style.disabled);
      this.dislikes[i].classList.remove(style.disabled);
    }
  }

  voteTrack(score: number, track: CurrentTrack): void {
    this.playerService.voteTrack(this.radio.id, score).subscribe(
      resp => {
        if (resp.status === 'success') {
          this.hasVoted(track);
        }
      },
      () => {
        this.hasVoted(track);
        this.disableVote();
      },
    );
  }

  hasVoted(track: CurrentTrack): void {
    const d = new Date();
    const timestamp = d.getTime();
    const id = generateTrackId(track);
    d.setTime(+d + 7200000); // 2 hours

    Cookies.set(`${this.radio.id}trackvoteid`, id, { path: '/', expires: d });
    Cookies.set(`${this.radio.id}trackvotets`, timestamp, {
      path: '/',
      expires: d,
    });
    this.disableVote();
  }
}
