import i18next from 'i18next';
import { Player } from '../view-controllers/main';
import style, { sliderContainer } from './widget.css';
import colorSvg from '../lib/svgs';
import { Radio } from '../services/player';

export default class PlayerUi {
  public player: Player;
  public radio: Radio;
  public multiStream: boolean;

  constructor(player: Player, radio: Radio, multiStream: boolean) {
    this.player = player;
    this.radio = radio;
    this.multiStream = multiStream;
  }

  public createPlayer(): string {
    let html = `<div id="player" class='${style.player}'>`;
    html += `<div class="${style.cover}"><img id="cover" src="${this.radio.logo}" alt="${this.radio.name}" /></div>`;
    if (this.player.like) {
      html += `<div class="${style.coverLike}">`;
      html += `<div class="${style.title}">${i18next.t('do_you_like')}</div>`;
      html += `<div class="${style.likeButtons}"><div class="like ${
        style.actionButton
      } ${style.like} ${style.scaleHover}">${colorSvg(
        'like',
        this.player.mainColor,
      )}</div>`;
      html += `<div class="dislike ${style.actionButton} ${style.like} ${
        style.scaleHover
      }">${colorSvg('dislike', this.player.mainColor)}</div></div>`;
      html += '</div>'; // .coverLike
    }
    html += `<div id="action-buttons" class="${style.actionButtons} ${
      !this.player.like ? style.noVote : ''
    }">`;
    if (this.player.like) {
      html += `<div class="like ${style.actionButton} ${style.like} ${
        style.scaleHover
      }">${colorSvg('like', this.player.mainColor)}</div>`;
    }
    html += `<div id="play-pause" class="${style.actionButton} ${style.play} ${
      style.scaleHover
    }"><span id="play">${colorSvg('play', this.player.mainColor)}</span>
       <span id="pause" class="${style.hidden}">${colorSvg(
      'pause',
      this.player.mainColor,
    )}</span>
       <div id="loader" class="${style.loader} ${style.hidden}"></div></div>`;
    if (this.player.like) {
      html += `<div class="dislike ${style.actionButton} ${style.like} ${
        style.scaleHover
      }">${colorSvg('dislike', this.player.mainColor)}</div>`;
    }
    html += '</div>'; // #action-buttons

    html += `<div id="infos" class="${style.infos}">`;
    html += `<div id="radio-infos" class="${style.radioInfos}">`;
    html += `<span>${this.radio.name}</span>`;
    if (this.player.listeners) {
      html += `<span class="${style.listeners}">${colorSvg(
        'listeners',
        this.player.secondaryColor,
      )}<span id="listeners">0</span><span class="${
        style.listenersLabel
      }"></span></span>`;
    }
    html += '</div>'; // #radio-infos

    html += `<div id="track" class="${style.track}">`;
    html += `<div id="current-title" class="${style.title}"><span></span></div>`;
    html += `<div id="current-artist" class="${style.artist}"><span></span></div>`;
    html += '</div>'; // #track

    html += `<div class="${style.volumeContainer}">`;
    html += `<span id="volume-on" class="${style.scaleHover} ${
      style.scaleMoreHover
    }">${colorSvg(
      'volumeOn',
      this.player.secondaryColor,
    )}</span><span id="volume-off" class="${style.hidden} ${style.scaleHover} ${
      style.scaleMoreHover
    }">${colorSvg('volumeOff', this.player.secondaryColor)}</span>`;
    html += `<div class="${sliderContainer}">`;
    html += `<div class="${style.sliderBackground}"></div>`;
    html += `<input id="slider" class="${style.slider}" type="range" min="0" max="100" value="75">`;
    html += '</div>'; // .slider-container

    if (this.player.itunes) {
      html += `<div id="download" class="${style.scaleHover} ${
        style.scaleMoreHover
      } ${style.downloadSvg} ${style.disabled}"><a title="${i18next.t(
        'download_track',
      )}" target="_blank">${colorSvg(
        'download',
        this.player.secondaryColor,
      )}</a>`;
      html += '</div>';
    }
    if (this.player.share) {
      html += `<div id="share" class="${style.scaleHover} ${
        style.scaleMoreHover
      } ${style.shareSvg}">${colorSvg('share', this.player.secondaryColor)}`;
      html += '</div>';
    }
    if (this.multiStream) {
      html += `<div id="HD" class="${style.hdButton} ${style.scaleHover} ${style.scaleMoreHover}">HD</div>`;
    }
    html += '</div>'; // #volume
    html += '</div>'; // #infos

    if (this.player.popup) {
      html += `<div id="popup" class="${style.popup} ${
        style.scaleHover
      }">${colorSvg('popup', this.player.secondaryColor)}</div>`;
    }

    if (this.player.share) {
      const iframeStyle = `border: 0px; border-radius: 5px;${
        this.player.format === 'h'
          ? 'min-width:430px; height: 145px;'
          : 'width:275px; min-height: 365px;'
      }width:${this.player.width}${this.player.sizeFormat};height:${
        this.player.height
      }px`;
      const iframeCode = `<iframe src="${window.location.href}" style="${iframeStyle}"></iframe>`;
      html += `<div id="share-zone" class="${style.shareZoneContainer} ${style.hidden}">`;
      html += `<div class=${style.shareZone}>`;
      html += `<div class="${style.shareText}">${i18next.t(
        'share_track',
      )}</div>`;
      html += `<div class="${style.shareButtons}">`;
      html += `<div id="share-facebook">${colorSvg('facebook', '')}</div>`;
      html += `<div id="share-twitter">${colorSvg('twitter', '')}</div>`;
      html += '</div>'; // .share-buttons
      html += `<div class="${style.shareText}">${i18next.t(
        'code_to_integrate',
      )}</div>`;
      html += `<div class="${style.integrationContainer}"><input type="text" readonly id="integration-code" class="${style.integrationCode}" value='${iframeCode}' />`;
      html += `<span id="integration-copy" class="${style.integrationCopy} ${
        style.scaleHover
      }">${colorSvg('copy', '#9c9c9c')}</span>`;
      html += `<span id="copied" class="${style.copied}">${i18next.t(
        'text_copied',
      )} ✅</span></div>`;
      html += '</div>'; // .share-zone
      html += `<div id="close-share" class="${style.closeShare}">${colorSvg(
        'close',
        this.player.secondaryColor,
      )}</div>`; // .share-zone
      html += '</div>'; // #share-zone
    }
    html += '</div>';
    return html;
  }
}
