import Component from 'lib/component';
import ErrorAlert from 'components/error-alert';
import * as queryString from 'query-string';
import { combineLatest } from 'rxjs';
import style from './main.css';
import PlayerService, { Radio, Stream } from '../services/player';
import Widget from '../components/widget';
import RadioNotFound from '../components/radio-not-found';

function initHeader(radio: Radio): void {
  document.getElementsByTagName('title')[0].innerText = `Player ${radio.name}`;
  const og = document.querySelector('[property="og:url"]');
  if (og) {
    og.setAttribute('content', radio.website);
  }
}

export interface Player {
  slug: string;
  mainColor: string;
  secondaryColor: string;
  format: 'h' | 'v';
  sizeFormat: 'px' | '%';
  width: number;
  height: number;
  cover: boolean;
  share: boolean;
  itunes: boolean;
  like: boolean;
  listeners: boolean;
  popup: boolean;
  autoplay: boolean;
}

interface QueryParameters {
  id: string;
  c: string; // mainColor
  c2: string; // secondaryColor
  f: 'h' | 'v'; // format
  fullsize: boolean; // sizeFormat
  w: string;
  h: string;
  p: string; // cover
  s: string; // share
  i: string; // itunes
  li: string; // like
  plc: string; // listeners
  popup: string;
  a: string;
}

export default class Main extends Component {
  public errorAlert = new ErrorAlert('Error loading data.');
  public content = new Component();

  public player: Player = {
    slug: '',
    mainColor: '#1e7fcb',
    secondaryColor: '#ffffff',
    format: 'h',
    sizeFormat: 'px',
    width: 470,
    height: 145,
    cover: true,
    share: true,
    itunes: false,
    like: true,
    listeners: false,
    popup: true,
    autoplay: false,
  };

  public constructor() {
    super({ className: style.mainView });

    const params = queryString.parse(location.search);
    const qParams: QueryParameters = {
      id: window.location.pathname.replace(/\//g, '') as string,
      c: params.c as string,
      c2: params.c2 as string,
      f: params.f as 'h' | 'v',
      h: params.h as string,
      w: params.w as string,
      s: params.s as string,
      p: params.p as string,
      i: params.i as string,
      li: params.li as string,
      plc: params.plc as string,
      popup: params.popup as string,
      fullsize: typeof params.fullsize !== 'undefined',
      a: params.a as string,
    };
    if (!qParams.id) {
      this.setupErrorView();
    } else {
      this.player.slug = qParams.id;
      this.player.mainColor = qParams.c || this.player.mainColor;
      this.player.secondaryColor = qParams.c2 || this.player.secondaryColor;
      this.player.format = qParams.f || this.player.format;
      this.player.sizeFormat = qParams.fullsize ? '%' : 'px';
      this.player.width = qParams.w
        ? Number.parseInt(qParams.w, 10)
        : this.player.width;
      this.player.height = qParams.h
        ? Number.parseInt(qParams.h, 10)
        : this.player.height;
      this.player.cover = !!qParams.p && qParams.p === '1';
      this.player.like = !!qParams.li && qParams.li === '1';
      this.player.share = !!qParams.s && qParams.s === '1';
      this.player.listeners = !!qParams.plc && qParams.plc === '1';
      this.player.itunes = !!qParams.i && qParams.i === '1';
      this.player.popup = !!qParams.popup && qParams.popup === '1';
      this.player.autoplay = !!qParams.a && qParams.a === '1';

      const slug = this.player.slug as string;
      this.setupViews();
      this.getConfig(slug);
      this.initWidgetStyle();
    }
  }

  private setupViews(): void {
    this.errorAlert.hide();
    this.add(this.errorAlert);
    this.add(this.content);
  }

  private setupErrorView(): void {
    this.add(this.errorAlert);
  }

  private getConfig(slug: string): void {
    const playerService = new PlayerService();
    const streamConfig = playerService.getStreamConfig(slug);
    const radioConfig = playerService.getRadioConfig(slug);
    combineLatest([streamConfig, radioConfig]).subscribe(([stream, radio]) => {
      if (!radio || !radio.id) {
        this.radioNotFound();
        return;
      }
      initHeader(radio);
      this.displayWidget(stream, radio);
    });
  }

  private displayWidget(stream: Stream[], radio: Radio): void {
    this.content.clear();
    this.content.add(new Widget(this.player, stream, radio));
  }

  private radioNotFound(): void {
    this.content.clear();
    this.content.add(new RadioNotFound());
  }

  initWidgetStyle(): void {
    const app = document.getElementById('app');
    if (app) {
      app.style.backgroundColor = this.player.mainColor;
      app.style.color = this.player.secondaryColor;
    }
  }
}
